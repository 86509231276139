import React, { useContext, useRef, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components'
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";

import { closeSubMenu, openSubMenu } from '../navBar/submenu.slice';
import { auth } from '../../firebase/firebase';
import { FirebaseContext } from '../../firebase/firebase.context';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  `

export const SignUpButton = styled.button`
  background: ${props => props.background || "transparent" };
  color: ${props => props.color || "black" };
  padding: 5px 10px;
  border-radius: 8px;
  border: ${props => props.border || "none" };
  cursor: pointer;
  margin-right: 10px;
  width: ${props => props.width || "auto" };
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
  `

export const Button = styled.button`
  background: ${props => props.background || "white" };
  border: ${props => props.border || '1px solid rgba(28, 34, 56, 1)' };
  color: ${props => props.color || "rgba(28, 34, 56, 1)" };
  padding: ${props => props.padding || "10px" };
  box-shadow: ${props => props.boxShadow || "none" };
  border-radius: ${props => props.borderRadius || "0" };
  width : ${props => props.width || "auto" };
  height : ${props => props.height || "auto" };
  cursor: pointer;
  ${(props) => props.disabled && css`
    cursor: none;
    pointer-events: none;
    opacity: 0.5;
    `
  }`

export const FormButton = styled(Button)`
  padding: 5px 30px;
  border-radius: 5px;
  color: ${props => props.color || "black" };
  background: ${props => props.background || "white" };
  `

const SubMenuButton = styled.p`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 5px;
    color: gray;
  }
`

const SubButtonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`

const SubContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`

{/* <SignUpButton onClick={login}
border={`1px solid black`}
>
  Service Provider Login
</SignUpButton>
<SignUpButton onClick={signUp}
background='#76C3D4' 
color='white'  >
  Service Provider SignUp
</SignUpButton> */}

const ServiceProviderMenu = () => {
  const { coords } = useSelector(state => state.subMenu)
  const { loginWithRedirect } = useAuth0();
  const container = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const applyCoords = useCallback(() => {
    const submenu = container.current
    const { top } = coords
    submenu.style.right = `5px`
    submenu.style.top = `${top}px`
  },[coords])

  useEffect(() => {
    applyCoords()
  },[coords])

  const signUp = () => {
    dispatch(closeSubMenu())
    history.push('/signup')
  }

  const login = () => {
    dispatch(closeSubMenu())
    loginWithRedirect()
  }

  return (
        <div className="dsubmenu" style={{ width: 150 }} ref={container}>
          <div className="dsubmenu__item" onClick={signUp} >
            <p className="dsubmenu__text">Create Account</p>
          </div>
          <div className="dsubmenu__item" onClick={login}>
            <p className="dsubmenu__text">Login</p>   
         </div>
       </div>
  )
}

const ClientDefaultMenu = () => {
  const { coords } = useSelector(state => state.subMenu)
  const container = useRef(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const applyCoords = useCallback(() => {
    const submenu = container.current
    const { top, left, right } = coords
    submenu.style.right = `${right}px`
    submenu.style.top = `${top}px`
    submenu.style.left = `${left}px`
  },[coords])

  useEffect(() => {
    applyCoords()
  },[coords])

  const login = () => {
    dispatch(closeSubMenu())
    history.push('/login?next=/')
  }

  return (
        <div className="dsubmenu" style={{ width: 100 }} ref={container}>
          <div className="dsubmenu__item" onClick={login}>
            <p className="dsubmenu__text">Login</p>   
         </div>
       </div>
  )
}

export const LoginButton = () => {
  const { isSubMenuOpen, coords, menu } = useSelector(state => state.subMenu)
  const dispatch = useDispatch()

  const displaySubMenu = (e, menu) => {
    e.stopPropagation()
    let { x, width, bottom, right } = e.target.getBoundingClientRect()
      console.log('bot',bottom);
      const left = x - (75-width)
    if (e.target.id !== 'container') {
      bottom += 10
    }
    dispatch(openSubMenu({ coords: { left, right: right + (width/2), top: bottom }, menu }))
  }

  const subMenuDisplay = useCallback((e, menu) => displaySubMenu(e, menu),[coords])

  const removeSubMenu = () => {
    dispatch(closeSubMenu())
  }

    return (
      <ButtonContainer>
        <SubButtonContainer id='container' onMouseEnter={(e) => subMenuDisplay(e,'client')} onMouseLeave={removeSubMenu}>
          <SubContainer>
            <SubMenuButton  >Client</SubMenuButton>
            { isSubMenuOpen ? <MdArrowDropUp size={20} />: <MdArrowDropDown size={20} /> }
          </SubContainer>
          { (isSubMenuOpen && menu==='client') ? <ClientDefaultMenu /> : null }
        </SubButtonContainer>
        <SubButtonContainer id='container' onMouseEnter={(e) => subMenuDisplay(e,'serviceProvider')} onMouseLeave={removeSubMenu}>
          <SubContainer>
            <SubMenuButton  >Service Provider</SubMenuButton>
            { isSubMenuOpen ? <MdArrowDropUp size={20} />: <MdArrowDropDown size={20} /> }
          </SubContainer>
          { (isSubMenuOpen && menu==='serviceProvider') ? <ServiceProviderMenu />: null   }
        </SubButtonContainer>
      </ButtonContainer>
    );
  };

export const LogoutButton = () => {
    const { logout, isAuthenticated } = useAuth0();
    const [user, loading, error] = useAuthState(auth);
    const { logout: firebaseLogout } = useContext(FirebaseContext)

    const handleLogout = async() => {
      if (isAuthenticated) {
        return logout({returnTo: window.location.origin})
      }
      if (user) {
        firebaseLogout()
      }
    }

    return (
      <SignUpButton data-test='logout' border={`1px solid black`} onClick={handleLogout} >Logout</SignUpButton>
    );
  };

export const TagButton = ({children}) => {

      return (
        <div className='tag' >
          <Button disabled={true} borderRadius='5px' >{children}</Button>
          <i className='tag__i fa fa-times'></i>
        </div>
      );
    };
