import React, { lazy, Suspense } from 'react'
import styled from 'styled-components'

import { Loading } from '../../Components/loader/loader'

const Heading = styled.p`
      font-size: 40px;
      font-weight: 800;
`

const Highlighter = styled.span`
  display: inline;
  color: #009dfe;
  font-size: 40px;
      font-weight: 800;
`

const Image = styled.img`
      width: 100%;
      object-fit: contain;
      height: 60vh;
      @media (min-width: 800px) {
        object-fit: cover;
        height: 80vh;
      }
`

const MobileContainer = styled.div`
    border-bottom: 1px solid black;
    @media (min-width: 800px) {
      display: none;
    }
`

const DesktopContainer = styled.div`
    display: none;
    @media (min-width: 800px) {
      display: flex;
      align-items: center;
      width: 90%;
      margin: auto;
      border-bottom: 1px solid black;
    }
`

const TextContainer = styled.div`
      margin: 0 5% 60px;
      @media (min-width: 800px) {
        width: 40%;
    }
`

const HeaderMobile = () => {
  return (
    <MobileContainer>
        <div className="">
            <Image className='' src='/main-min.png' alt="card-icon" />
        </div>
        <TextContainer>
          <Heading>RETHINKING <Highlighter> THE WAY YOU </Highlighter>  LOOK AFTER  YOUR CAR</Heading>
        </TextContainer>
      </MobileContainer>
  )
}

const HeaderDesktop = () => {
  return (
    <DesktopContainer>
        <TextContainer>
          <Heading>RETHINKING <Highlighter> THE WAY YOU </Highlighter>  LOOK AFTER  YOUR CAR</Heading>
        </TextContainer>
        <div className="">
            <Image className='' src='/main-min.png' alt="card-icon" />
        </div>
      </DesktopContainer>
  )
}

const BelowFold = lazy(() => import(/* webpackChunkName: "below-fold" */ './below-fold'))

function Home() {

  return (
    <div >
      {/* <div className="header" data-test="header">
          <div className="header__card">
            <h1 className="header__h1">Rethinking the way you look after your car.</h1>
          </div>
      </div> */}
      <HeaderMobile />
      <HeaderDesktop />
      <Suspense fallback={<Loading loading={true}/>}>
        <BelowFold/>
      </Suspense>
    </div>
  )
}

export default React.memo(Home)
