import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components'
import { TbCircleDotFilled } from "react-icons/tb";
import { FaPlus } from "react-icons/fa";
import { useAuth0 } from '@auth0/auth0-react';
import PaystackPop from '@paystack/inline-js'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { Loading, Uploading } from '../../../Components/loader/loader';
import { Button, FormButton } from '../../../Components/button/Button';
import { InteractiveModal } from '../../../Components/modal/Modal';
import { fetchServicesSubscription } from '../../../Components/subscriptions/services.slice';
import { fetchExistingSubscription } from '../../../Components/subscriptions/subscription.slice';
import SubscriptionSkeleton from '../../../Components/skeletons/subscription';
import customAxios from '../../../redux/axios/axios'

const Container = styled.div`
    padding: 50px;
`

const SubContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin: 50px auto;
`

export const Card = styled.div`
    padding: 20px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 5px;
    width: 250px;
`;

export const Heading = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px auto;
`

export const PriceContainer = styled.div`
    display: flex;
    gap: 5px;
    margin: 30px auto;
    align-items: flex-end;
`

export const Price = styled.div`
    font-size: x-large;
    font-weight: 700;
`

export const Interval = styled.p`
    padding-bottom: 4px;
`

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Option = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`

export const ButtonContainer = styled.div`
    margin: 30px auto;
    text-align: center;
`

const popup = new PaystackPop()

function CreateSubscription() {
    const { fetching, existingSubscription, existingSubscriptionError } = useSelector(state => state.existingSubscription)
    const {loading, subscription, subscriptionError} = useSelector(state => state.servicesSubscription)
    const { metadata } = useSelector(state => state.metadata)
    const [doesProfileExist, SetDoesProfileExist] = useState({ checkComplete: false, exists: false })
    const [testPayment, setTestPayment] = useState({ loading: false, success: false, error: '' })
    const [subscriptionPayment, setSubscriptionPayment] = useState({ creating: false, success: false, error: '' })
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)
    const [selectedPlan, setSelectedPlan] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { getAccessTokenSilently, user:{ email } } = useAuth0();
    const dispatch = useDispatch()
    const history = useHistory()
    const { state } = useLocation()
    let accessToken = useRef()

    const options = {
        services: {
            premium: ['Listings Page', `Up to ${subscription?.plans?.premium?.max} Concurrent Bookings at any time`],
            basic: ['Listings Page', `Up to ${subscription?.plans?.basic?.max} Concurrent Bookings at any time` ],
            standard: ['Listings Page', `Up to ${subscription?.plans?.standard?.max} Concurrent Bookings at any time`],
            free: ['Listings Page for 1 month', `Up to ${subscription?.plans?.free?.max} Concurrent Bookings`]
        },
        towing: {
            premium: ['Listings Page', `Up to ${subscription?.plans?.premium?.max} Registered Towers`],
            basic: ['Listings Page', `Up to ${subscription?.plans?.basic?.max} Registered Towers` ],
            standard: ['Listings Page', `Up to ${subscription?.plans?.standard?.max} Registered Towers`],
            free: ['Listings Page for 1 month', `Up to ${subscription?.plans?.free?.max} Registered Towers`]
        }
    }

    const subOptions = [
        { 
            plan: 'Free',
            amount: subscription?.plans?.free?.amount,
            planCode: subscription?.plans?.free?.planCode,
            options: state?.profileType === 'Vehicle Maintenance' ? options.services.free: options.towing.free,
        },
        { 
            plan: 'Basic',
            amount: subscription?.plans?.basic?.amount,
            planCode: subscription?.plans?.basic?.planCode,
            options: state?.profileType === 'Vehicle Maintenance' ? options.services.basic: options.towing.basic,
        },
        { 
            plan: 'Standard',
            amount: subscription?.plans?.standard?.amount,
            planCode: subscription?.plans?.standard?.planCode,
            options: state?.profileType === 'Vehicle Maintenance' ? options.services.standard: options.towing.standard,
        },
        {
            plan: 'Premium',
            amount: subscription?.plans?.premium?.amount,
            planCode: subscription?.plans?.premium?.planCode,
            options: state?.profileType === 'Vehicle Maintenance' ? options.services.premium: options.towing.premium,
        }
    ]

    const openModal = (plan) => {
        setSelectedPlan(plan)
        setIsModalOpen(true)
    }

    const createTestPayment = async() => {
        setIsModalOpen(false)
        setIsBtnDisabled(true)
        popup.newTransaction({
            key: 'pk_test_64c1d034dab38282701fa12d0af9f7e40314a5a9',
            email,
            amount: 100,
            metadata: {
                profileType: state?.profileType
            },
            onSuccess: (transaction) => {
                createSubscription()
            },
            onCancel: () => {
                setIsBtnDisabled(false)
                setSelectedPlan('')
            },
            onError: () => {
                setSelectedPlan('')
                setTestPayment({...testPayment, error: true })
            }
        })
    }

    const navigateToProfileCreation = async() => {
        try {
            let token = await getAccessTokenSilently({
                                    audience: process.env.REACT_APP_AUTH0_AUDIENCE
                                })
            // create a pending entry
            await customAxios.post('/subscriptions/create-db-entry',{ 
                email,
                profileType: state?.profileType,
                subscriptionPlan: selectedPlan.plan
                },{ params: { id: metadata?.user_id },
                headers: { 'Authorization': `Bearer ${token}` }});
            if (state?.profileType === 'Vehicle Maintenance') {
                history.push('/services/create/car-maintenance', { profileType: state?.profileType, selectedPlan })
            } else if (state?.profileType === 'Roadside Assist') {
                history.push('/services/create/roadside-assist', { profileType: state?.profileType, selectedPlan })
            }
        } catch (error) {
            console.log(error);
            
        }
    }

    const createSubscription = async() => {
        try {
            setSubscriptionPayment({...subscriptionPayment, creating: true })
            const { planCode } = selectedPlan
            const accessToken = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
            })
            let currentDate = new Date();
            currentDate.setMinutes(currentDate.getMinutes() + 1);
            let start_date = currentDate.toISOString()
            await customAxios.post('/subscriptions/create', { 
                plan: planCode, 
                email, 
                profileType: state?.profileType, 
                start_date,
                ...(planCode.includes('FREE') && { isFreeTrial: true })
             },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                     }
                 }
            )
            setSubscriptionPayment({...subscriptionPayment, success: true, creating: false })
            navigateToProfileCreation()
        } catch (error) {
            setIsBtnDisabled(false)
            setSubscriptionPayment({...subscriptionPayment, error: true, creating: false })
        }
    }

    useEffect(() => {
        if (!state?.profileType) {
            history.push('/services/create')
        }
    },[])

    useEffect(() => {
        const checkForServicesProfile = async() => {
          try {
            accessToken.current = await getAccessTokenSilently({
              audience: process.env.REACT_APP_AUTH0_AUDIENCE
            })
            const { data: { Items }} = await customAxios.get(`/services/${encodeURIComponent(email)}`,{
                                                                                   headers: {
                                                                                       Authorization: `Bearer ${accessToken.current}`
                                                                                    },
                                                                                    params: { email }
                                                                                })
            Items.length === 1 && SetDoesProfileExist({ checkComplete: true, exists: true })   
            !Items.length && SetDoesProfileExist({ checkComplete: true, exists: false })   
          } catch (error)  {
            console.log(error);
          } 
        }

        const checkForRoadsideProfile = async() => {
            try {
              accessToken.current = await getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_AUDIENCE
              })
              const { data: { Items }} = await customAxios.get('/roadside-assist/check',{
                                                                                     headers: {
                                                                                         Authorization: `Bearer ${accessToken.current}`
                                                                                      },
                                                                                      params: { email }
                                                                                  })
              Items.length === 1 && SetDoesProfileExist({ checkComplete: true, exists: true })   
              !Items.length && SetDoesProfileExist({ checkComplete: true, exists: false })    
            } catch (error)  {
              console.log(error);
            } 
          }
          
          if (state?.profileType  === 'Vehicle Maintenance') {
            checkForServicesProfile()
          } else {
            checkForRoadsideProfile()
          }
      },[])

    useEffect(() => {
        if (!doesProfileExist.exists && doesProfileExist.checkComplete) {
            dispatch(fetchExistingSubscription({ email, profileType: state?.profileType }))
        }
    },[doesProfileExist])

    useEffect(() => {
        if (existingSubscription) {
            if (state?.profileType === 'Vehicle Maintenance') {
                history.push('/services/create/car-maintenance', { profileType: state?.profileType, selectedPlan })
            } else if (state?.profileType === 'Roadside Assist') {
                history.push('/services/create/roadside-assist', { profileType: state?.profileType, selectedPlan })
            }
        } else if (!fetching && !existingSubscription) {
            dispatch(fetchServicesSubscription(state?.profileType))
        }
    },[existingSubscription, fetching])

    useEffect(() => {
        if (testPayment.error || subscriptionPayment.error) {
            setIsBtnDisabled(false)
        }
    },[testPayment, subscriptionPayment])

    return (
        <Container>
            {
                !doesProfileExist.checkComplete ? <Loading loading={true} /> :
                doesProfileExist.exists ? <article className='dashboard__notif'>
                                      <p>Pre existing Profile Found</p>
                                      <p >You can not create a profile whilst you have an active one</p>
                                  </article>: 
                fetching ? <Loading loading={loading} label={'Checking for Subscription..'} />:
                loading ? <SubscriptionSkeleton />:
                subscriptionError ? <p className='error'>Error Fetching Data. Try again</p>:
                existingSubscriptionError ?  <p className='error'>Error Checking for Existing Subscription. Try again</p>:
            (
            <div>
            <h1>Choose your Subcription Plan</h1>
            <p>Subscription plans can be changed later</p>
            <SubContainer>
            {
                subOptions.map(({ plan, options, amount, planCode }, idx) => {
                    return (
                        <Card key={idx}>
                            <Heading>
                                <TbCircleDotFilled color={plan === 'Free' ? 'black': plan === 'Basic' ? 'blue': plan === 'Standard' ? 'green': 'purple' } />
                                <p style={{ color: plan === 'Free' ? 'black': plan === 'Basic' ? 'blue': plan === 'Standard' ? 'green': 'purple', fontWeight: 'bold' }}>{plan}</p>
                            </Heading>
                            <PriceContainer>
                                <Price>R{amount}</Price>
                                { plan !== 'Free' ? <Interval>/ month </Interval>: null }
                            </PriceContainer>
                            <OptionsContainer>
                                {options.map((el,idx) => <Option key={idx}>
                                                            <FaPlus size={10} />
                                                            <p>{el}</p>
                                                        </Option>)}         
                            </OptionsContainer>
                            <ButtonContainer>
                            <Button
                                background={plan === 'Free' ? 'black': plan === 'Basic' ? 'blue': plan === 'Standard' ? 'green': 'purple'}
                                color='white' 
                                border='none'
                                padding='15px'
                                borderRadius='5px'
                                width='200px'
                                disabled={isBtnDisabled}
                                onClick={() => openModal({ plan, amount, planCode })}>
                                    Subscribe
                            </Button>
                            </ButtonContainer>
                        </Card>
                    )
                })
            }
            </SubContainer>
            {   subscriptionPayment.creating ? <Uploading text="Creating Subscription" color={'black'}/> :
                subscriptionPayment.success ? <Uploading text="Processing. Please wait" color={'black'}/> :
                testPayment.error ? <p className='error'>Test Payment Error. Try again</p> : 
                subscriptionPayment.error ? <p className='error'>Subscription Payment Error. Try again</p> : null }
            {
            (isModalOpen && selectedPlan.plan !== 'Free') ? (
                <InteractiveModal height='90vh'> 
                    <h3>You have Selected A {selectedPlan.plan} Plan</h3>
                    <p style={{ lineHeight: 1.5, marginBottom: 10 }}>We will create a Test Payment of ZAR 1.00 to test if your Bank Card is valid. If it succeeds then we will proceed with the Subscription creation process.</p>
                    <div className="imodal__btns">
                        <FormButton background='#5282BD' 
                                    color='white' 
                                    onClick={createTestPayment}>
                                    Continue
                        </FormButton>
                        <FormButton borderRadius='5px' 
                                    onClick={() => setIsModalOpen(false)}>
                                        Cancel
                        </FormButton>
                    </div>
                    
                </InteractiveModal>
                ) : (isModalOpen && selectedPlan.plan === 'Free') ? (
                    <InteractiveModal height='90vh'> 
                        <h3>You have Selected A {selectedPlan.plan} Plan</h3>
                        <p style={{ lineHeight: 1.5, marginBottom: 10 }}>Free trial will expire after 30 days.</p>
                        <div className="imodal__btns">
                            <FormButton background='#5282BD' 
                                        color='white' 
                                        onClick={() => {
                                            setIsModalOpen(false)
                                            createSubscription()
                                        }}>
                                        Continue
                            </FormButton>
                            <FormButton borderRadius='5px' 
                                        onClick={() => setIsModalOpen(false)}>
                                            Cancel
                            </FormButton>
                        </div>
                        
                    </InteractiveModal>
                ): null 
            }
        </div>
        )}
        </Container>
    )
}

export default CreateSubscription