import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  loading : true,
  subscription: null,
  subscriptionError: ''
}

const servicesSubscription = createSlice({
  name: 'servicesSubscription',
  initialState,
  reducers: {
    fetchServicesSubscriptionRequest(state) {
      state.loading = true
    },
    fetchServicesSubscriptionSuccess(state, action) {
        state.loading = false
        state.subscription = action.payload
    },
    fetchServicesSubscriptionError(state, action) {
      state.loading = false
      state.subscriptionError = action.payload
    },
  }
})

export const { fetchServicesSubscriptionRequest, fetchServicesSubscriptionSuccess, fetchServicesSubscriptionError } = servicesSubscription.actions

export const fetchServicesSubscription = (type) => async (dispatch) => {
  dispatch(fetchServicesSubscriptionRequest())
  try {
      let res
      if (type === 'Vehicle Maintenance') {
        res = await customAxios.get('/subscriptions/services')
      } else if (type === 'Roadside Assist') {
        res = await customAxios.get('/subscriptions/towing')
      }
      dispatch(fetchServicesSubscriptionSuccess(res.data.Items[0]))
  } catch (error) {
      dispatch(fetchServicesSubscriptionError(error))
  }      
}

export default servicesSubscription.reducer
