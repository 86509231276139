import { BsPlusSquare, BsFileMinus } from 'react-icons/bs'
import { TextAreaFieldReq } from "../field/field"

export function ListItems({data,handleItemAdd,handleItemChange,handleItemRemove,placeholder,name,rows}) {
  return (
    <div>
        {data.map((key, idx) => {
              return (
                <div key={idx} className='form__address-block'>
                  <TextAreaFieldReq name={name} id={key} className='form__addr' rows={rows || '3'} value={data[idx]} onChange={(e)=>handleItemChange(e,idx)} placeholder={placeholder} />
                  {idx !== 0 ? <BsFileMinus onClick={()=>handleItemRemove(name,idx)} color='black' size={15} cursor='pointer' />  : null}
                </div>
              )
            })}
            <p  style={{textAlign:'right'}}><BsPlusSquare onClick={() => handleItemAdd(name)} color='black' size={15} cursor='pointer' /></p>
          
    </div>
  )
}

export const ListItemsWithAutoCompletion = ({data,handleItemAdd,handleItemChange,handleItemRemove,placeholder,name,autocomplete}) => {
    return (
        <div>
            {data.map((key, idx) => {
                return (
                  <div id={key} key={idx} className='form__address-block'>
                    <div style={{width:'100%'}}>
                    <input className='form__addr' list={`autocompletion${idx}`} type='text' name={name} 
                            value={data[idx].address} onChange={(e)=>handleItemChange(e,idx)} placeholder={placeholder} />
                    <datalist id={`autocompletion${idx}`}>
                      {autocomplete.map(item => {
                        return (
                          <option style={{width:'100%'}} key={Math.random()} value={item}>{item}</option>
                        )
                      })}
                    </datalist>
                    </div>
                    {idx !== 0 ? <BsFileMinus onClick={()=>handleItemRemove(name,idx)} color='black' size={15} cursor='pointer' />  : null}
                  </div>
                )
              })}
            <p  style={{textAlign:'right'}}><BsPlusSquare onClick={() => handleItemAdd(name)} color='black' size={15} cursor='pointer' /></p>
            
        </div>
    )
}

export const InputWithAutoCompletion = ({value, label, name, placeholder, type, onChange, className, id,autocomplete}) => {
  return (
    <div className="form__item">
    {label && <label >{`${label}*`}</label>}
    <input type={type ? type : 'text'} value={value} name={name}  className={className || "form__input" }
    id={id ? id : null} placeholder={placeholder} onChange={onChange} list={`autocompletion${name}`} required />
    <datalist id={`autocompletion${name}`}>
        {autocomplete.map(item => {
          return (
            <option key={Math.random()} value={item}>{item}</option>
          )
        })}
    </datalist>
</div>
  )
}

