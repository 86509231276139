import { Geo } from 'aws-amplify'; 

export const searchOptionsWithSearchAreaConstraints = {
  countries: ["ZAF"], 
  maxResults: 1, 
}

const searchOptionsForAutocompletion = {
  countries: ["ZAF"],
  maxResults: 10
}

export const textSearch = async (text) => {
  try {
    let results = await Geo.searchByText(text,searchOptionsForAutocompletion)
    return results
  } catch (error) {
    console.log(error);
  }
}

export const reverseGeoCode = async (coords) => {
  try {
    let results = await Geo.searchByCoordinates(coords,searchOptionsForAutocompletion)
    return results
  } catch (error) {
    console.log(error);
  }
}

export const geocode = async(location) => {
  const coordsObject = []
  let res
  try {
    for (let i = 0; i < location.length; i++) {
      const value = location[i]
      res = await Geo.searchByText(value,searchOptionsWithSearchAreaConstraints)
      coordsObject.push({coordinates :res[0].geometry.point,address:value })
    }
    return coordsObject
  } catch (error) {
    console.log(error);
  }
  
}
