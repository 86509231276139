import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useAuthState } from 'react-firebase-hooks/auth';
import { API, graphqlOperation } from 'aws-amplify';

import { messagesByChannelID, getChatsListByAuthor } from '../graphql/queries';
import { onCreateMessage } from '../graphql/subscriptions';
import { updateMessage } from '../graphql/mutations';
import { decrementUnreadMessages } from '../Components/badge/badge.slice';
import { sendMessageSuccess } from '../Components/chat/chat.slice';
import { auth } from '../firebase/firebase';
import customAxios from '../redux/axios/axios'

export const useGetAndUpdateLastMessage = (channelID,chatEmail) => {
    const [{ accessToken, email }, userLoading, error] = useAuthState(auth);
    const [dblastMessage, setDBLastMessage] = useState({ message: '', error: '', fetched: false })
    const [profile, setProfile] = useState({ profile: {}, error: '', fetched: false })
    const [loading, setLoading] = useState(true)
    
  
    useEffect(() => {
      API
          .graphql(graphqlOperation(messagesByChannelID, {
            channelID,
            sortDirection: 'ASC'
          },{ authToken: accessToken }))
          .then((response) => {
            const items = response?.data?.messagesByChannelID?.items; 
            if (items && items.length) {
                const messages = items.filter(item => {
                  return ((item.author === email && item.receiver === chatEmail) || (item.receiver === email && item.author === chatEmail)) 
                })
                if (messages.length) {
                  setDBLastMessage({ message: messages[messages.length - 1], fetched: true })
                }   
            }
          })
          .catch(err => {
            setDBLastMessage({ message: '', fetched: false, error: err })
          })
        
    },[]);

    useEffect(() => {
      const getProfileDetail = async() => {
        try {
          const { data } = await customAxios.get(`/services/${encodeURIComponent(chatEmail)}`)
          setProfile({ profile: data.Items[0], fetched: true, error: '' })
        } catch (error) {
          setProfile({ profile: {}, fetched: false, error })
        }
      }

      getProfileDetail()
    },[])

    useEffect(() => {
      if (profile.fetched && dblastMessage.fetched) {
        setLoading(false)
      }
    },[profile,dblastMessage])

    useEffect(() => {
        const subscription = API
          .graphql(graphqlOperation(onCreateMessage, {}, accessToken))
          .subscribe({
            next: (event) => {
              const newMessage = event.value.data.onCreateMessage
              if (newMessage.channelID === channelID) {
                if ((newMessage.author === email && newMessage.receiver === chatEmail) || (newMessage.receiver === email && newMessage.author === chatEmail)) {
                  setDBLastMessage({...dblastMessage, message: newMessage});
                }  
              }
            },
            error: (err) => console.log(err)
          });
        
        return () => {
          subscription.unsubscribe();
        };
      }, []);
  
    return { dblastMessage, profile, loading }
  }

export const useListAndCreateMessage = (channelID, chatEmail) => {
  const { unreadMessages } = useSelector(state => state.unreadMessages )
    const [{ email, accessToken }, loading, error] = useAuthState(auth);
    const [messages, setMessages] = useState({ items:[], err:'', fetched: false, loading: true });
    const messagesRef = useRef(messages.items)
    const [messageText, setMessageText] = useState('')
    const todayRef = useRef()
    const dispatch = useDispatch()
    const isBookingAuthorisedRef = useRef(false)
    let dblastMessage

    const dateFormating = (messages) => {
      const dates = {}
      const msgs = []
      let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const today = new Date().toLocaleString("en-US", options)
      for (const msg of messages) {  
        const date = new Date(msg.createdAt)
        const stringDate = date.toLocaleDateString("en-US", options)

        if (!dates[stringDate]) {
          if (stringDate === today) {
            msgs.push({ type: 'date', value: 'Today', createdAt: Math.random() }, msg)
            todayRef.current = true
          } else {
            msgs.push({ type: 'date', value: stringDate, createdAt: Math.random() }, msg)
          }
          dates[stringDate] = stringDate
        } else {
          msgs.push(msg)
        }
      }
      return msgs
    }

    const fetchMessages = async () => {
      API
          .graphql(graphqlOperation(messagesByChannelID, {
            channelID,
            sortDirection: 'ASC'
          },{ authToken: accessToken }))
          .then(async(response) => {
            const items = response?.data?.messagesByChannelID?.items;
            if ( items && items.length) {
              const chatMessages = items.filter(item => {
                if (item.body.includes('bac_') && item.author === chatEmail && item.receiver === email) {
                  isBookingAuthorisedRef.current = true
                }
                return ((item.author === email && item.receiver === chatEmail) || (item.receiver === email && item.author === chatEmail)) 
              })
              let dateFormattedMessages = dateFormating(chatMessages)
              setMessages({...messages, items: dateFormattedMessages, loading: false, fetched: true });
              messagesRef.current = dateFormattedMessages
              dblastMessage =  chatMessages[chatMessages.length - 1]
              if ( dblastMessage && dblastMessage.author !== email && !dblastMessage.read) {
                  let input = { id:dblastMessage.id, read: true};
                  try {
                    await API.graphql(graphqlOperation(updateMessage, { input }, { authToken: accessToken }))
                    Object.keys(unreadMessages).length && dispatch(decrementUnreadMessages(dblastMessage.author))
                } catch (error) {
                    console.warn(error);
                }
              }
            } else {
              setMessages({...messages, fetched: true, loading: false })
            }
          })
          .catch(err => {
            setMessages({...messages, err, loading: false, fetched: false })
          })
    }

    const refetch = async() => {
      setMessages({ items:[], err:'', fetched: false, loading: true })
      await fetchMessages()
    }

    useEffect(() => {
      const getChatMessages = async() => {
        fetchMessages()
      }

      getChatMessages()
    }, [channelID]);
    
      useEffect(() => {
        const subscription = API
          .graphql(graphqlOperation(onCreateMessage, {}, accessToken))
          .subscribe({
            next: (event) => {
              const { receiver, author, body } = event.value.data.onCreateMessage
              if (receiver === email && author === chatEmail) {
                const currentMessages = messagesRef.current
                if (body.includes('bac_') && author === chatEmail && receiver === email) {
                  isBookingAuthorisedRef.current = true
                }
                if (todayRef.current) {
                  setMessages({...messages,items:[...currentMessages,event.value.data.onCreateMessage], loading: false});
                  messagesRef.current = [...currentMessages,event.value.data.onCreateMessage] 
                } else {
                  setMessages({...messages,items:[...currentMessages, { type: 'date', value: 'Today', createdAt: Math.random() }, event.value.data.onCreateMessage], loading: false });
                  messagesRef.current = [...currentMessages, { type: 'date', value: 'Today', createdAt: Math.random() }, event.value.data.onCreateMessage]
                  todayRef.current = true 
                } 
              } else if (author === email && receiver === chatEmail) {
                dispatch(sendMessageSuccess({ message: event.value.data.onCreateMessage }))
              }
            },
            error: (err) => console.log(err)
          });
        
        return () => {
          subscription.unsubscribe();
        };
      }, []);

    return { messages, messagesRef, messageText, setMessageText, setMessages, todayRef, refetch, isBookingAuthorised: isBookingAuthorisedRef.current }
}

export const useGetChatsList = (newChat) => {
    const [{ email, accessToken }, loading, error] = useAuthState(auth);
    const [chats, setChats] = useState({ loading: true, items: [], err: '', fetched: false })
    const chatsRef = useRef(chats.items)

  useEffect(() => {
    API
      .graphql(graphqlOperation(getChatsListByAuthor, {
        author: email,
        sortDirection: 'DESC',
      },{ authToken: accessToken }))
      .then((response) => {
        const items = response?.data?.getChatsListByAuthor?.items;
        if ( newChat || (items && items.length)) {
          let msgRecepeints = []
          for (const item of items) {
            if (!msgRecepeints.includes(item.receiver) && item.channelID.includes('_vm')) { 
                msgRecepeints.push(item.receiver)
            }
          }
          if (newChat && !msgRecepeints.includes(newChat)) {
            msgRecepeints.unshift(newChat)
          }
          chatsRef.current = msgRecepeints
          setChats({...chats, items: [...chatsRef.current], loading: false });
          } else {
            setChats({...chats, loading: false, fetched: true})
          }
      })
      .catch(err => {
        setChats({...chats, loading: false, err, fetched: false })
      })
  }, []);

    return { chats, chatList: chatsRef.current }
}

