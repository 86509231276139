import {useState,useEffect, useRef} from 'react'
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha"

import {InputFieldReq } from '../../Components/field/field'
import useGetFormValuesAndMethods from '../../utils/form-hook';
import { useCreateAccountMutation } from '../../Components/api/api.slice';
import { Uploading } from '../../Components/loader/loader';
import { useAssignRoleMutation } from '../../Components/api/api.slice';
import { FormButton } from '../../Components/button/Button';
import PasswordStrengthMeter from  '../../utils/password-strength'

function SignUp() {
    const {isModalOpen} = useSelector(state => state.modal)
    const [{formData},formMethods,formErrors] = useGetFormValuesAndMethods()
    const { isAuthenticated } = useAuth0();
    const [isCaptchaSet, setIsCaptchaSet] = useState(false)
    const captchaRef = useRef(null)
    const {email,password,cPassword} = formData

    const [createAccount, { isError, isLoading, isSuccess, data, error }] = useCreateAccountMutation()
    const [assignRole, { isError: assignRoleError, isLoading: isAssigning, isSuccess: isAssigned, error: AssignError }] = useAssignRoleMutation()


    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const { innerWidth: width, innerHeight: height } = window;

    console.log(windowDimensions)

    function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(()=> {  
      if (isSuccess) {
        assignRole({USER_ID: data.user.user_id, data:{roles:['rol_x32sBZ43MNl36dvM']}})
      } 
    },[isSuccess])

    console.log('create',isSuccess, data, isError, error);
    console.log('assign', isAssigned, AssignError);

  
    useEffect(()=>{
      isError && setIsCaptchaSet(false)
    },[isError])

    const handleOnChange = (e) => formMethods.handleOnChange(e)

    const isBtnDisabled = () => {
      return !isCaptchaSet || cPassword !== password || formErrors.email || formErrors.password?.len || formErrors.password?.chars
    }

    const handleSubmit = async (e) => {
      e.preventDefault()
      const token = captchaRef.current.getValue();
      captchaRef.current.reset();
      const postData = {
        email: email,
        user_metadata: { isServiceProvider: true },
        blocked: false,
        email_verified: false,    
        connection: "Username-Password-Authentication",
        password: password,
        verify_email: true,
        token
      }
      createAccount(postData)   
   }

  return (
    <>
    {
      !isAuthenticated ? (
      <div>
          { isAssigned ? <Redirect to='/signup/success' /> : (
            <div className='signup__container'>
              <article className='signup__root'>
              <div className='signup__headings'>
                <h1 className='signup__h1' >Let us market your services for you</h1>
                <p className='signup__p' >Sign up for your Service Provider Account</p>
              </div>
              <form  className='signup-form' >
                <div className='form__fields' >
                  <InputFieldReq label='Email' 
                                  value={email} 
                                  name='email' 
                                  placeholder='company@org.com' 
                                  onChange={handleOnChange}
                                  bold 
                                  labelColor={ windowDimensions.width < 850 ? 'white': 'black'}
                  />
                  {<p className="form__error">{formErrors['email']}</p>}  
                  <InputFieldReq label='Password' 
                                  type={'password'} 
                                  value={password} 
                                  name='password' 
                                  placeholder='**********' 
                                  onChange={handleOnChange}
                                   labelColor={ windowDimensions.width < 850 ? 'white': 'black'}
                                  bold
                  />
                  {password ?  <PasswordStrengthMeter password={password} /> : null}
                  {formErrors['password'] && <p className="form__error">{formErrors['password'].len}</p>}   
                  {formErrors['password'] && <p className="form__error">{formErrors['password'].chars}</p>}
                  <InputFieldReq label='Confirm Password' 
                                  type={'password'} 
                                  value={cPassword} 
                                  name='cPassword' 
                                  placeholder='**********' 
                                  onChange={handleOnChange}
                                   labelColor={ windowDimensions.width < 850 ? 'white': 'black'}
                                  bold
                  />
                  {cPassword !== password && <p className="form__error">passwords don't match</p>}      
                  </div> 
                  <div className="captcha">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                              onChange={() => setIsCaptchaSet(true)}
                              onExpired={() => setIsCaptchaSet(false)}
                              ref={captchaRef}
                    /> 
                    <div style={{ marginTop: 20 }}>
                        <FormButton background="#90CFE2"  
                                    border='none'
                                    disabled={isBtnDisabled()} 
                                    onClick={handleSubmit}>
                                      Create My Account
                        </FormButton>
                    </div>
                  </div> 
              </form>
              <div style={{ marginTop: 20 }}>
              {isLoading ? <Uploading text="creating account" color={'white'} textColor="white" /> : isAssigning ? <Uploading color={'white'}  textColor="white" text="assigning permissions" /> :null}
              {isError ? <p className='error'>{error.message}</p> : assignRoleError ? <p className='error'>{assignRoleError.message}</p> : null }
              </div>
              </article>
          </div>
          )}
    </div>
    ) : <div style={{ textAlign: 'center', marginTop: '20px'}}>
            <h1 style={{ color: 'red'}}>You are already logged in</h1>
        </div>}
    </>
  )
}


export default SignUp