import React, { Suspense, lazy } from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';
import CookieConsent from "react-cookie-consent";

import { Loading } from './Components/loader/loader';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history'
import Auth0ProtectedRoute from './auth/protected-route';
import FirebaseProtectedRoute from './firebase/protected-route';
import NavBar from './Components/navBar/NavBar';
import Home from './views/home/home';
import SignUp from './views/signup/SignUp';
import SignupSuccess from './views/signup/Success';
import CreateService from './views/services/create-service/CreateService';
import CreateRoadsideAssistService from './views/services/create-service/RoadsideAssist'
import CreateVehicleService from './views/services/create-service/VehicleMaintenance'
import Services from './views/services/list/Services';
import ServiceDetail from './views/services/detail/ServiceDetail';
import { StepperProvider } from './Components/stepper/context';
import OnlineStatusProvider from './Components/offline/Context';
import { FirebaseContextProvider } from './firebase/firebase.context';
import { ChatBadgeContextProvider } from './Components/chat/badge.context';
import ReviewsList from './views/reviews/ReviewsList';
import Offline from './Components/offline/Offline';
import CreateBooking from './views/bookings/create';
import BookingDetail from './views/bookings/detail';
import Chats from './views/chats/Chats';
import Login from './views/login/login';
import Info from './views/bookings/info';
import Pay from './views/payments/pay';
import PaymentSuccess from './views/payments/pay-success';
import PaymentCancelled from './views/payments/pay-cancel';
import PaymentError from './views/payments/pay-error';
import Account from './firebase/acount';
import BookingCheckoutSuccess from './views/bookings/checkout';
import PrivacyPolicy from './views/privacy-policy/privacy-policy';
import CookiePolicy from './views/cookie-policy/cookie-policy';
import TermsOfUse from './views/terms/terms-of-use';
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './sass/main.scss';
import awsconfig from './aws-exports';

import CreateSubscription from './views/services/create-service/CreateSubscription';

const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Dashboard'))

function App() {

  useEffect(() => {
    import('aws-amplify').then(module => {
      module.Amplify.configure(awsconfig)  
    })
  },[])


  const acceptCookies = () => {

  }
  
  return (
    <Router>
      <Auth0ProviderWithHistory>
        <FirebaseContextProvider>
          <ChatBadgeContextProvider>
            <OnlineStatusProvider>
              <StepperProvider>
                    <div className='App' >
                      <CookieConsent
                          location="bottom"
                          buttonText="OK"
                          onAccept={acceptCookies}
                          cookieName="auth0"
                          acceptOnScroll
                          acceptOnScrollPercentage={80}
                          style={{ background: "#2B373B" }}
                          buttonStyle={{ color: "white", fontSize: "13px", backgroundColor: 'green' }}
                          declineButtonStyle={{ fontSize: "14px" }}
                          expires={150}
                        >
                          This website uses cookies to enhance the user experience. By continuing to use this website you agree to our Cookie policy. <a rel='noreferrer' target='_blank' href='https://tapaservice.co.za/cookie-policy' style={{ textDecoration: 'underline', cursor: 'pointer'}}>Learn More</a>
                        </CookieConsent>
                        <Offline />
                        <NavBar />
                        <Switch>
                          <Route path="/" exact ><Home/></Route>
                          <Route path="/login" exact ><Login/></Route>
                          <Route path="/booking/checkout-success" exact ><BookingCheckoutSuccess/></Route>
                          <Route path="/pay" exact ><Pay/></Route>
                          <Route path="/payment-error" exact ><PaymentError/></Route>
                          <Route path="/payment-cancel" exact ><PaymentCancelled/></Route>
                          <Route path="/payment-success" exact ><PaymentSuccess/></Route>
                          <Route path="/signup" exact ><SignUp/></Route>
                          <Route path="/services" exact ><Services/></Route>
                          <Route path="/service/:email" exact ><ServiceDetail/></Route>
                          <Route path="/service/:email/reviews" exact ><ReviewsList/></Route>
                          <Route path="/signup/success" exact ><SignupSuccess/></Route>
                          <Route path="/privacy-policy" exact ><PrivacyPolicy/></Route>
                          <Route path="/cookie-policy" exact ><CookiePolicy/></Route>
                          <Route path="/terms-of-use" exact ><TermsOfUse/></Route>
                          <FirebaseProtectedRoute path="/account" exact ><Account /></FirebaseProtectedRoute>
                          <FirebaseProtectedRoute path="/booking" exact ><BookingDetail/></FirebaseProtectedRoute>
                          <FirebaseProtectedRoute path="/booking/create" exact ><CreateBooking/></FirebaseProtectedRoute>
                          <FirebaseProtectedRoute path="/booking/create/info" exact ><Info/></FirebaseProtectedRoute>
                          <FirebaseProtectedRoute path="/chats" exact ><Chats/></FirebaseProtectedRoute>
                          {/* <FirebaseProtectedRoute path="/services/booking" exact ><CreateBooking /></FirebaseProtectedRoute> */}
                          <Auth0ProtectedRoute path="/services/create" exact component={CreateService} />
                          <Auth0ProtectedRoute path="/sub/create" exact component={CreateSubscription} />
                          <Auth0ProtectedRoute path="/services/create/car-maintenance" exact component={CreateVehicleService} />
                          <Auth0ProtectedRoute path="/services/create/roadside-assist" exact component={CreateRoadsideAssistService} />
                          <Suspense fallback={<Loading loading={true} />} >
                            <Auth0ProtectedRoute path="/dashboard/:id" exact component={Dashboard} />
                          </Suspense>
                        </Switch>
                    </div>
              </StepperProvider>
            </OnlineStatusProvider>
          </ChatBadgeContextProvider>
        </FirebaseContextProvider>
    </Auth0ProviderWithHistory>
    </Router>
  )
}

export default App
