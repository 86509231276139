import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'

const containerStyle = {
  paddingLeft: '50px',
  textAlign: 'left'
}

const headingStyle = {
  marginTop: '35px',
  marginBottom: '35px',
  color: 'green',
  fontSize: '14px',
  fontWeight: 'bold'
}

const textStyle = {
  fontSize: '12px',
  lineHeight: '30px'
}

const CTA = {
  padding: '10px',
  fontSize: '12px',
  color: 'white',
  background: 'green',
  borderRadius: '8px',
  border: 'none',
  marginTop: '50px',
  cursor: 'pointer'
}

function CreateProfileSuccess() {
    const {  user:{ email } } = useAuth0();
  const history = useHistory()
    
  return (
    <div style={containerStyle} >
        <h2 style={headingStyle}>Profile created Successfully!</h2>
        <p style={textStyle}>You have successfully created your Service Provider Profile. It is still inactive at this point. <br/>
        To activate your profile you need to log in and upload your documents.<br/>
        You need to upload the following: <br/>
          <ol style={{ paddingLeft: '50px'}}>
            <li>RMI Certificate (.pdf)</li>
            <li>Any Supporting documents (.pdf)</li>
          </ol> 
        Our team will verify your documents with relevant authorities.<br/>
        Once we have verified your documents we will send you a profile activation confirmation email. 
        
        </p>
        <button style={CTA} onClick={() => history.push(`/dashboard/${encodeURIComponent(email)}`)}>
          Go to Dashboard
        </button>
    </div>
  )
}

export default CreateProfileSuccess