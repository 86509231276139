import { createSlice } from "@reduxjs/toolkit"

import { geocode } from "../../../utils/map"
import customAxios from '../../../redux/axios/axios'

const initialState = {
  updating : false,
  updated: false,
  updateError: ''
}

const profileUpdate = createSlice({
  name: 'profileUpdate',
  initialState,
  reducers: {
    updateProfileRequest(state) {
      state.updating = true
    },
    updateProfileSuccess(state, action) {
        state.updating = false
        state.updated = true
    },
    updateProfileError(state, action) {
      state.updating = false
      state.updateError = action.payload
    },
    updateProfileReset(state) {
      state.updating = false
      state.updateError = ''
      state.updated = false
    },
  }
})

export const { updateProfileRequest, updateProfileSuccess, updateProfileError, updateProfileReset } = profileUpdate.actions

export const updateProfile = (data,type,id,token) => async (dispatch) => {
  const geocodedLocations = await geocode(data.address)
  try {
      dispatch(updateProfileRequest())
      await customAxios.post('/services',{...data,geocodedLocations },{ params: { type, id, update: true },
                                                                        headers: { 'Authorization': `Bearer ${token}` }});
      dispatch(updateProfileSuccess())
  } catch (error) {
      dispatch(updateProfileError(error))
  }
}

export default profileUpdate.reducer
