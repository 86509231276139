import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { BsStarFill } from 'react-icons/bs'
import { GiGears } from 'react-icons/gi'
import { MdLocationOn } from 'react-icons/md'
import { LogoSkeleton } from '../skeletons/product-list'

const ServiceContainer = styled.div`
    padding: 15px 10px 15px 0;
    cursor: pointer;
    max-width: 400px;
`

const ProfileHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const LogoContainer = styled.div`
    max-width: 400px;
    font-size: 0;
`

const Logo = styled.img`
    object-fit: cover;
    border-radius: 8px 8px 0 0;
`

const TextualInfo = styled.div`
    background-color: rgba(118, 195, 212, 0.23);
    border-radius: 0 0 8px 8px;
    padding: 9px;
`

const CompanyTitle = styled.div`
    display: flex;
    gap: 5px;
    width: 80%;
`

const CompanyName = styled.div`
    margin: 4px 0;
    white-space: nowrap;
    max-width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 16px;
`

const Rating = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;
`

const Text = styled.p`
    font-weight: 500;
    margin: 4px 0;
`

const Services = styled(Text)`
    justify-self: flex-end;
    white-space: nowrap;
`

const NonOverflowText = styled(Text)`
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const StarsContainer = styled.div`
    display: flex;
    gap: 8px;
`

const Location = styled(Rating)`
    gap: 5px;
`


function Service({ profile, type }) {
    const [loaded, setLoaded] = useState(false);
    const { companyName, logo, statement, rating, email, companyID, reviewsCount, address, servicesCount } = profile
    const history = useHistory()

    const goToDetail = () => {
        const url = `/service/${encodeURIComponent(email)}?type=${type}${companyID ? `&cid=${companyID}`: ''}`
        history.push(url)
    }

  return (
    <ServiceContainer onClick={goToDetail}>
        <LogoContainer>
            <Logo src={`${process.env.REACT_APP_S3_BUCKET_URL}/${logo}`} 
                  alt="service-thumbnail" 
                  onLoad={() => setLoaded(true)} 
                  style={{ width: 0, height: 0, ...(loaded && { width: "100%", height: '205px' })}}
            />
            {
                !loaded ? <LogoSkeleton />: null
            }
        </LogoContainer>
        <TextualInfo>
            <ProfileHeading>
                <CompanyTitle>
                    <GiGears size={20} />
                    <CompanyName>{companyName}</CompanyName>
                </CompanyTitle>
                <Services>{servicesCount} services</Services>
            </ProfileHeading>
            <NonOverflowText>{statement}</NonOverflowText>
            <Rating>
                { rating ? <Text>{rating}</Text>  : null }   
                <StarsContainer>
                    {
                        Array.from(new Array(Math.floor(5))).map((val,idx) => <BsStarFill key={idx} size={12} color={ rating ? '#FFE662': 'black'} /> ) 
                    }
                </StarsContainer>
                { reviewsCount ? <Text>({reviewsCount})</Text>: null }
            </Rating>
            <Location>
                <MdLocationOn size={12} />
                <NonOverflowText>{address[0].address}</NonOverflowText>
            </Location>
        </TextualInfo>
    </ServiceContainer>
  )
}

export default Service