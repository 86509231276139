import React, { useReducer, useEffect } from "react"
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { auth } from "./firebase";

export const FirebaseContext = React.createContext()
const googleProvider = new GoogleAuthProvider();

const initialState = { isAuthenticated: false, user: null }

const reducer = (state, action) => {
    switch (action.type) {
    case 'authenticate':
        return { isAuthenticated: true, user: action.payload.user };
    case 'logout':
        return { isAuthenticated: false, user: null };
    default:
        return initialState;
    }
}



export const FirebaseContextProvider = ({ children }) => {
    const [firebaseAuth, dispatch] = useReducer(reducer, initialState);

    useEffect(()=>{
        auth.onAuthStateChanged((user) => {
            if (user) {
                user.accessToken = user.refreshToken
                if (!auth.user) {
                    dispatch({ type: 'authenticate', payload: user })
                }
            } else {
                dispatch({ type: 'logout' })
            }
        })
    },[])

    const signInWithGoogle = async () => {
        try {
            await signInWithPopup(auth, googleProvider)
            
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
        };
        
    const logout = () => {
        signOut(auth);
        };

    return (
        <FirebaseContext.Provider value={{ firebaseAuth, logout, signInWithGoogle  }}>
            {children}
        </FirebaseContext.Provider>
    )
}