import { createSlice } from "@reduxjs/toolkit"

import customAxios from '../../redux/axios/axios'

const initialState = {
  loading : false,
  isBookingUpdated: false,
  bookingUpdateError: ''
}

const bookingUpdate = createSlice({
  name: 'bookingUpdate',
  initialState,
  reducers: {
    updateBookingRequest(state) {
      state.loading = true
    },
    updateBookingSuccess(state, action) {
        state.loading = false
        state.isBookingUpdated = true
    },
    updateBookingError(state, action) {
      state.loading = false
      state.bookingUpdateError = action.payload
    },
    updateBookingReset(state) {
      state.loading = false
      state.bookingUpdateError = ''
      state.isBookingUpdated = false
    },
  }
})

export const { updateBookingRequest, updateBookingSuccess, updateBookingError, updateBookingReset } = bookingUpdate.actions

export const updateBooking = (companyEmail, clientEmail, bookingStatus, companyName, branch, amount, companyID, paymentOption, token) => async (dispatch) => {
  dispatch(updateBookingRequest())
  try {
      await customAxios.post('/booking/update',{ clientEmail, companyEmail, bookingStatus, companyName, branch, amount, companyID, paymentOption },
      { headers: { 'Authorization': `Bearer ${token}` }});
      dispatch(updateBookingSuccess())
  } catch (error) {
      dispatch(updateBookingError(error.message))
  }      
}

export const completeBooking = (companyEmail, clientEmail, bookingStatus) => async (dispatch) => {
  dispatch(updateBookingRequest())
  try {
      await customAxios.post('/booking/completion',{ clientEmail, companyEmail, bookingStatus });
      dispatch(updateBookingSuccess())
  } catch (error) {
      dispatch(updateBookingError(error.message))
  }      
}

export default bookingUpdate.reducer
