import { useHistory } from "react-router-dom"

const Modal = ({message,link,warning}) => {
  const history = useHistory()

  const handleClick = () => {
    history.push(`/${link}`)
  }

  return (
    <div className={`modal ${warning ? 'modal__warn' : null}`}>
      <p className='modal__p'>{message}</p>
      {link && <p onClick={handleClick} className="modal__link">view</p>}
    </div>
  )
}

export const InteractiveModal = ({ children, height }) => {

  return (
    <div className="imodal">
      <div className="imodal__overlay" style={{ height }}>
        <div className="imodal__content">
          { children }
        </div>
      </div>
    </div>
  )
}

export default Modal
