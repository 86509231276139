import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import {BsCircleFill} from 'react-icons/bs'
import { useAuthState } from 'react-firebase-hooks/auth';
import PropTypes from 'prop-types'
import { BsCheckSquareFill } from 'react-icons/bs'
import Skeleton from 'react-loading-skeleton'
import { auth } from '../../firebase/firebase';
import { useGetAndUpdateLastMessage, useGetChatsList } from '../../utils/graphql-hooks'
import { useGetAndUpdateLastMessage as useClientGetAndUpdateLastMessage } from '../../utils/client-graphql-hooks';

const ProfileContainer = styled.div`
    padding: 8px 0px;
    cursor: pointer;
`;

const Container = styled.div`
    height: 100%;
    width: 100%;
    padding: 0px 10px;
    display: flex;
    align-items: center;
`

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
`

const Image = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`

const TextData = styled.div`
    height: 100%;
    width: 100%;
    text-align: left;
`

const ChatName = styled.p`
    font-weight: 600;
    margin-bottom: 4px;
`

const Caption = styled.div`
    display: flex;
    alignItems: center;
    gap: 5px;
`

const LastMessage = styled.p`
`

function Profile({email}) {
    const channelID = email.concat('_vm')
    const { chats: { items } } = useGetChatsList()
    const {dblastMessage, loading } = useGetAndUpdateLastMessage(channelID, items)
    const { user } = useAuth0();

  return (
    <>
        { loading ? <Skeleton height={50} containerClassName='profile__skeleton'  /> : 
            <div className='profile'>
                <div className='profile__info'>
                    <img className='profile__img' src={`${process.env.REACT_APP_S3_BUCKET_URL}/assets/identicon.png`} alt="profile-pic" />
                    <div>
                    <p className='profile__email'>{email}</p>
                    <div className='profile__lm'>
                        { user.email === dblastMessage?.author && <BsCheckSquareFill size={15} /> }
                        <p>{dblastMessage?.body}</p>
                    </div>
                    </div>   
                </div>
                {dblastMessage && !dblastMessage.read && dblastMessage.author !== user.email &&  <BsCircleFill size={15} color='blue' /> } 
            </div> }
    </>
        )
    }

export const SPProfile = ({ email, index, openChat, setOpenChatCreds, active }) => {
    const [{ email: userEmail}, userLoading, error] = useAuthState(auth);
    const { dblastMessage, profile, loading } = useClientGetAndUpdateLastMessage(userEmail.concat('_vm'),email)
    const S3_BUCKET_URL = 'https://tapaservice.s3.amazonaws.com'
    const { companyName, logo, statement, address } = profile.profile

    const selectChat = () => {
        openChat(email, index)
        setOpenChatCreds({ companyName, logo, statement, address, email })
    }

    useEffect(() => {
        if (profile.error) {
            alert('ChatError: ','Error fetching chat info, please try again later')
        }
        if (dblastMessage.error) {
            alert('ChatError: ','Error fetching last chat message, please try again later')
        }
    },[profile, dblastMessage])

    return (
        <div>
        { Object.keys(profile.profile).length ? (
            <ProfileContainer onClick={selectChat}
                              className={active === index ? 'profile__active': null}>
            <Container>   
                    <Wrapper>
                        <Image
                            src={`${S3_BUCKET_URL}/${logo}`}
                        />  
                        <TextData>
                            <ChatName>{companyName}</ChatName>
                            <Caption>
                                { userEmail === dblastMessage?.message?.author ? <BsCheckSquareFill size={15} /> : null }
                                <LastMessage>{dblastMessage?.message?.body}</LastMessage>
                            </Caption>
                        </TextData>
                    </Wrapper>
                    { dblastMessage.message && !dblastMessage.message.read && dblastMessage.message.author !== userEmail ? <BsCircleFill size={15} color='blue' /> : null  }     
            </Container>
            </ProfileContainer>
        ) : loading ? <Skeleton height={50} containerClassName='profile__skeleton'  /> : null }
        </div>
    )
}

Profile.propTypes = {
    email: PropTypes.string.isRequired,
  }

export default Profile;
