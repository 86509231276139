import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import queryString from 'query-string'
import Skeleton from 'react-loading-skeleton'
import { Button } from '../../Components/button/Button'
import axios from '../../redux/axios/axios'
import { DetailItem, Heading, ActionsContainer, Error } from '../bookings/detail'

const Payment = styled.div`
@media (min-width: 450px) {
    height: 90vh;
    display: flex;
    flex-direction: column;
    
    align-items: center;
  }
`

const Container = styled.div`
    padding: 0 10px;
    @media (min-width: 450px) {
        padding: 20px;
        background: #f9f9f9;
        width: 80%;
        margin: 15px auto;
      }
`

function Pay() {
  const [verifyToken, setVerifyToken] = useState({ verifying: true, verified: false, verificationError: '' })
  const [isBtnDisabled, setIsBtnDisabled] = useState(false)
  const [url, setUrl] = useState({ fetching: false, value: null })
  const location = useLocation()
  
  const { token, amount, transactionReference, name, bankReference } = queryString.parse(location.search)
  const data = { amount, transactionReference, name, bankReference,
                 searchField: name.toLowerCase(),
                 ...(location.state && { companyName: location.state.companyName, 
                                         companyEmail: location.state.companyEmail, 
                                         branch: location.state.branch, 
                                         clientEmail: location.state.clientEmail,
                                         vehicleRegistration: location.state.vehicleRegistration,
                                         isWeb: true })  }

  useEffect(() => {
    if (url.value) {
      window.location.href = url.value
    }
  },[url])

  useEffect(() => {
    const beginTokenVerification = async() => {
      try {
          await axios.post('/verify-token',{ token })
          setVerifyToken({...verifyToken, verifying: false, verified: true })
      } catch (error) {
          setVerifyToken({...verifyToken, verifying: false, verified: false, verificationError: 'Could not verify token' })
      }
    }

    beginTokenVerification()
  },[])

  const confirmPayment = async() => {
    setUrl({...url, fetching: true })
    setIsBtnDisabled(true)
        try {
            const { data: { url }} = await axios.post('/payment',data)
            setUrl({ value: url, fetching: false })
        } catch (error) {
            setUrl({...url, fetching: false })
            setIsBtnDisabled(false)
            return alert('Could not generate payment url. Please try again')
        }    
  }

  return (
    <Payment>
        <Heading>Make Payment</Heading>
        <Container >
        <section className="booking__block">
            <h4 style={{ fontSize: '16px' }}>Payment Details</h4>
            <DetailItem>
                    <p className='detail__subheading'>Name:</p>
                    <p>{decodeURIComponent(name)}</p>
            </DetailItem>
            <DetailItem>
                    <p className='detail__subheading'>Amount (ZAR):</p>
                    <p>{amount}</p>
            </DetailItem>
            <DetailItem>
                    <p className='detail__subheading'>Transaction Reference:</p>
                    <p>{transactionReference}</p>
            </DetailItem>
            <DetailItem>
                    <p className='detail__subheading'>Bank Reference:</p>
                    <p>{bankReference}</p>
            </DetailItem>
        </section>
        <ActionsContainer>
            {
                verifyToken.verifying ? <Skeleton height={30} width={'100%'} /> :
                verifyToken.verificationError ? 
                <Error>TokenError: Token Expired.</Error> :
                verifyToken.verified ? <Button background='#5282BD' 
                                              color='white'
                                              borderRadius="5px"
                                              disabled={isBtnDisabled}
                                              onClick={confirmPayment}
                                              >
                                              { url.fetching ? 'Loading..' : 'Start Payment' }
                                      </Button> : null }
        </ActionsContainer>
        </Container>
  </Payment>
  )
}

export default Pay