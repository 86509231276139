import Skeleton from 'react-loading-skeleton'
import styled from 'styled-components'

const LogoContainer = styled.div`
    width: 100%;
    height: 205px;
    border-radius: 8px 8px 0 0;
`

const ProductListingSkeleton = () => {
    return (
        <div className='service__container'>
        <div className="service__img">
            <Skeleton height={150} />
        </div>
        <div className="service__text">
            <Skeleton width={250} className="service__name" />
            <Skeleton width={250} className="service__name" />
            <Skeleton width={50} className="service__name" />    
        </div>
    </div>
    )

}

export const LogoSkeleton = () => {
    return (
        <div style={{ width: '100%', height: '205px' }}>
            <Skeleton height={'100%'} width={'100%'} />
        </div>
    )
}

export default ProductListingSkeleton